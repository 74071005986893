<script
  setup
  lang="ts"
  generic="TData extends Record<string, any> = Record<string, any>"
>
import type { TVReturnType } from 'tailwind-variants'
import type {
  SimpleTableColumn,
  SimpleTableData,
  SimpleTableHeaderSlot,
  SimpleTableItemSlot,
} from './types'
import { simpleTableVariant } from './style'

const props = withDefaults(
  defineProps<{
    columns: SimpleTableColumn[]
    data: SimpleTableData<TData>
    orientation?: 'horizontal' | 'vertical'
    clickable?: boolean
    hoverable?: boolean
    dividedRows?: boolean
    stripedRows?: boolean
    showGridlines?: boolean
    rounded?: boolean
    padding?: 'sm' | 'md' | 'lg' | 'none'
    fontSize?: 'sm' | 'md' | 'lg' | 'xl' | 'base'
    footer?: boolean
    sticky?: boolean
    // @ts-expect-error - TVReturnType requires generics, but for this we don't need them
    variant?: TVReturnType
  }>(),
  {
    columns: () => [],
    data: () => [],
    orientation: 'horizontal',
    clickable: false,
    hoverable: true,
    dividedRows: true,
    stripedRows: false,
    showGridlines: false,
    rounded: false,
    padding: 'md',
    fontSize: 'sm',
    footer: false,
    variant: () => simpleTableVariant,
  },
)
const emit = defineEmits<{
  'click:row': [value: TData]
}>()

defineSlots<
  {
    header: (scope: SimpleTableHeaderSlot) => any
    item: (scope: SimpleTableItemSlot<TData>) => any
  } & {
    [K in keyof TData as `header-${string & K}`]: (
      scope: SimpleTableHeaderSlot,
    ) => any
  } & {
    [K in keyof TData as `item-${string & K}`]: (
      scope: SimpleTableItemSlot<TData, K>,
    ) => any
  }
>()

const orientation = computed(() => props.orientation)
const clickable = computed(() => props.clickable)
const hoverable = computed(() => props.hoverable)
const dividedRows = computed(() => props.dividedRows)
const stripedRows = computed(() => props.stripedRows)
const showGridlines = computed(() => props.showGridlines)
const rounded = computed(() => props.rounded)
const padding = computed(() => props.padding)
const fontSize = computed(() => props.fontSize)
const sticky = computed(() => props.sticky)

const variant = memoizeTv(
  (props.variant as typeof simpleTableVariant)({
    get orientation() {
      return orientation.value
    },
    get hoverable() {
      return hoverable.value
    },
    get clickable() {
      return clickable.value
    },
    get dividedRows() {
      return dividedRows.value
    },
    get stripedRows() {
      return stripedRows.value
    },
    get showGridlines() {
      return showGridlines.value
    },
    get rounded() {
      return rounded.value
    },
    get padding() {
      return padding.value
    },
    get fontSize() {
      return fontSize.value
    },
    get sticky() {
      return sticky.value
    },
  }),
)

const onClickRow = (value: any) => {
  if (props.clickable) emit('click:row', value)
}

const SimpleTableComponent = defineAsyncComponent(() => {
  return import(
    orientation.value === 'horizontal'
      ? '@ui/components/SimpleTable/SimpleTableHorizontal.vue'
      : '@ui/components/SimpleTable/SimpleTableVertical.vue'
  )
})
</script>

<template>
  <component
    :is="SimpleTableComponent"
    :data="data"
    :columns="columns"
    :variant="variant"
    :footer="footer"
    @click:row="onClickRow"
  >
    <template
      v-for="(_, slotName) in $slots"
      :key="slotName"
      #[slotName]="slotProps"
    >
      <!-- @ts-expect-error -->
      <slot :name="slotName" v-bind="slotProps" />
    </template>
  </component>
</template>
